import { useEffect } from 'react'

export function useOnOutsideClick(
	ref: React.RefObject<HTMLInputElement | HTMLDivElement | null>,
	onClose: () => void,
) {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: MouseEvent) {
			if (ref?.current && !ref.current.contains(event.target as Node)) {
				onClose()
			}
		}

		function handleKeyDown(e: KeyboardEvent) {
			if (e.key === 'Escape') {
				onClose()
			}
		}

		// Bind the event listeners
		document?.addEventListener('mousedown', handleClickOutside)
		document?.addEventListener('keydown', handleKeyDown)

		return () => {
			// Unbind the event listeners on clean up
			document?.removeEventListener('mousedown', handleClickOutside)
			document?.removeEventListener('keydown', handleKeyDown)
		}
	}, [ref, onClose])
}
